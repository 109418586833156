
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faSteeringWheel as farSteeringWheel,
        faChevronRight as farChevronRight,
        faChevronLeft as farChevronLeft,
        faChevronDown as farChevronDown,
        faChevronUp as farChevronUp,
        faCircleUser as farCircleUser,
    } from '@fortawesome/pro-regular-svg-icons';
    import { loadScript, loadStylesheet } from '@/utils/helpers';

    export default {
        name: 'CareerOneBanjoHome',

        components: {
            FontAwesomeIcon,
            JobTabsScroll: () => import('@/components/Job/JobTabsScroll'),
            BanjoChat: () => import('@/components/Banjo/BanjoChat'),
            PoweredByDecidr: () => import('@/components/Landings/CareerOne/PoweredByDecidr'),
        },

        data() {
            return {
                isBanjoSlideOpen: false,
                isMounted: false,
                player: null,
                icons: {
                    farSteeringWheel,
                    farChevronRight,
                    farChevronLeft,
                    farChevronDown,
                    farChevronUp,
                    farCircleUser,
                },
            };
        },

        watch: {
            isBanjoSlideOpen(isOpen) {
                const banjoHomeRef = this.$refs.banjoHome;
                banjoHomeRef.classList.toggle('is-banjo-opened', isOpen);
                banjoHomeRef.classList.toggle('is-banjo-closed', !isOpen);
                document.querySelector('body').classList.toggle('mobile-no-scroll', isOpen);
            },
        },

        mounted() {
            this.loadVideoScript();

            if (this.$mq === 'xl' || this.$mq === 'inf') {
                this.openBanjo();
            }

            this.isMounted = true;
        },

        methods: {
            loadVideoScript() {
                loadStylesheet('https://vjs.zencdn.net/8.16.1/video-js.min.css');
                loadScript('https://vjs.zencdn.net/8.16.1/video.min.js', () => {
                    this.initVideo();
                });
            },

            initVideo() {
                this.player = window.videojs('player');
            },

            toggleBanjo() {
                this.isBanjoSlideOpen = !this.isBanjoSlideOpen;
            },

            openBanjo() {
                this.isBanjoSlideOpen = true;
            },

            onUserEngagementStarted() {
                this.player.pause();
            },
        },
    };
